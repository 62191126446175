import { Component, OnInit, Renderer2 } from "@angular/core";
import { ModalController, NavParams, Platform } from "@ionic/angular";
import { ChemModalPage } from "../chem-modal/chem-modal.page";
import { OrnamentalData, OrnamentalItem } from "../../data/ornamental-data";
import { WeedData, WeedItem } from "../../data/weed-data";
import { ChemData, ChemItem, ChemOptions } from "../../data/chem-data";
import { Subscription } from "rxjs";
import { AnalyticsService } from "../../services/analytics.service";

@Component({
  selector: "app-ornamental-modal",
  templateUrl: "./ornamental-modal.page.html",
  styleUrls: ["./ornamental-modal.page.scss"]
})
export class OrnamentalModalPage implements OnInit {

  constructor(
    public modalController: ModalController,
    private navParams: NavParams,
    public platform: Platform,
    public render: Renderer2,
    private analytics: AnalyticsService,
  ) {}

  ngOnInit() {
    this.item = OrnamentalData.getInstance().getItem(this.navParams.data.itemId);
    this.analytics.logEvent("ornamental_modal_open", {
      ornamental_common_name: this.item.commonName,
    });
    if (!window.history.state.modal) {
      const modalState = { modal: true };
      history.pushState(modalState, null);
    }
    this.updateChemItems();
  }

  public item: OrnamentalItem;
  public backButtonSub: any;
  public event: Subscription;
  weedTiming: "postemergence" | "preemergence" | "any" = "any";
  chemItems: ChemItem[]
  weedItems = WeedData.getInstance().getData();
  weedItem: WeedItem | null = null;

  ionViewDidEnter() {
    this.backButtonSub = this.platform.backButton.subscribeWithPriority(
      -1,
      () => this.onBack()
    );
  }

  ionViewWillLeave() {
    this.backButtonSub.unsubscribe();
  }

  async onBack() {
    this.closeModal();
  }

  async close() {
    await this.modalController.dismiss();
  }

  onWeedTimingChange(event: any) {
    this.weedTiming = event.detail.value;
    this.updateChemItems();
  }

  onWeedChange(event) {
    this.weedItem = event.value;
    this.updateChemItems();
  }

  updateChemItems() {
    const chemOptions: ChemOptions = {
      weedName: this.weedItem === null ? null : this.weedItem.commonName,
      desirableType: "ornamental",
      desirableName: this.item.commonName,
    };
    if (this.weedTiming !== "any") {
      chemOptions.weedTiming = this.weedTiming;
    }
    let chemItems = ChemData.getInstance().advancedSearch(chemOptions);
    chemItems = chemItems.filter((chemItem) => ['E', 'G', 'L'].includes(chemItem.specificControlCode) || (this.weedItem == null));
    const controlCodeMap = {
      "E": 1,
      "G": 2,
      "L": 3,
    }
    chemItems = chemItems.sort((itemA, itemB) => controlCodeMap[itemA.specificControlCode] - controlCodeMap[itemB.specificControlCode]);
    this.chemItems = chemItems;
  }

  setError(event: any) {
    console.log("err in modal:", event);
    return
  }

  getItem() {
    return this.item;
  }

  async closeModal() {
    await this.modalController.dismiss();
  }

  async openChemModal(id: number) {
    const modal = await this.modalController.create({
      component: ChemModalPage,
      cssClass: "modal-details",
      componentProps: {
        "itemId": id,
      },
    });
    return await modal.present();
  }
}

import { Component, OnInit, Renderer2 } from "@angular/core";
import { ModalController, NavParams, Platform } from "@ionic/angular";
import { Subscription } from "rxjs";
import { AnalyticsService } from "../../services/analytics.service";

@Component({
  selector: "app-modal",
  templateUrl: "./modal.page.html",
  styleUrls: ["./modal.page.scss"],
})
export class ModalPage implements OnInit {
  constructor(
    public modalController: ModalController,
    private navParams: NavParams,
    public platform: Platform,
    public render: Renderer2,
    private analytics: AnalyticsService,
  ) {}

  ngOnInit() {
    this.content = this.navParams.data.content;
    this.title = this.navParams.data.title;
    this.analytics.logEvent("nav_modal_open", {
      nav_title: this.title,
    });
    if (!window.history.state.modal) {
      const modalState = { modal: true };
      history.pushState(modalState, null);
    }
  }
  public title: any;
  public content: any;
  public id: string;
  public backButtonSub: any;
  public event: Subscription;
  public appTiming : string;
  public grassType : string;
  public controlType : string;

  ionViewDidEnter() {
    this.backButtonSub = this.platform.backButton.subscribeWithPriority(
      -1,
      () => this.onBack()
    );
  }
  
  ionViewWillLeave() {
    this.backButtonSub.unsubscribe();
  }

  async onBack() {
    this.closeModal();
  }
  async close() {
    await this.modalController.dismiss();
  }
  segmentChangedApp(event: any) {
    this.appTiming = event.detail.value;
    this.updateControl();
  }
  segmentChangedGrass(event: any) {
    this.grassType = event.detail.value;
    this.updateControl();
  }
  updateControl() {
    this.controlType = this.appTiming+"-"+this.grassType;
  }

  setError(event: any) {
    console.log("err in modal:", event);
    return
  }

  getContent() {
    return this.content;
  }

  async closeModal() {
    await this.modalController.dismiss();
  }
}
